<template>
  <!-- Form -->
  <h1 style="margin: 20px">Register Form</h1>
  <form class="form">
    <p-email-input
      v-model="register.emailAddress"
      name="emailAddress"
      :label="$t('formElementName.email')"
    />
    <p-password-input
      v-model="register.password"
      name="password"
      :label="$t('formElementName.password')"
      @on-input="(va) => console.log(va)"
    />

    <p-text-input
      v-model="passwordAgain"
      name="passwordAgain"
      :type="isEyeOpen ? 'text' : 'password'"
      :label="$t('formElementName.passwordAgain')"
      @focus="onFocused()"
      @blur="onBlured()"
    >
      <template #append>
        <p-icon
          v-if="passwordAgain || isFocused"
          color="foreground-secondary"
          size="xmd"
          :icon="isEyeOpen ? 'eye-open-regular' : 'eye-close-solid'"
          @on-click="isEyeOpen = !isEyeOpen"
        />
      </template>
    </p-text-input>
  </form>
</template>

<script setup>
import { useForm } from 'vee-validate'

const isFocused = ref(false)
const isEyeOpen = ref(false)
const passwordAgain = ref('')
const isBlured = ref(false)

const onFocused = () => {
  isFocused.value = true
  isBlured.value = false
}

const onBlured = () => {
  isBlured.value = true
  isFocused.value = false
}

const register = reactive({
  emailAddress: '',
  password: '',
  passwordAgain: '',
})

const schema = {
  emailAddress: 'required',
  password: 'required',
  passwordAgain: 'required|passwordConfirmed:@password',
}

useForm({
  validationSchema: schema,
})
</script>
