<template>
  <div class="overview">
    <div class="container mb-32">
      <div class="row">
        <div class="col-12">
          <p class="f-title-24 text-center">
            🎉🎉🎉🎉🎉🎉<br />
            bu smooth Deployment experience için <br />
            <bold class="my-12 f-title-32-bold">Ahmet Navruz</bold>'a<br />
            bol bol teşekkür ederiz <br />🫶🫶🫶🫶🫶🫶
          </p>
        </div>
      </div>
    </div>
    <div v-if="$device.isDesktop">
      <div style="width: 400px; margin: auto">
        <p-segmented-control v-model="modelValue" :items="psegmentControl" />
        {{ modelValue }}
        <div style="margin-top: 20px">
          Router Change
          <p-segmented-control :items="psegmentControlRouter" size="sm" />
        </div>
      </div>
      <div style="display: flex; justify-content: center; align-items: center; padding: 6">
        <div style="width: 600px; padding-top: 50px">
          <p-otp-text-input
            :ttl="10"
            label="Cep telefonu doğrulama kodu"
            :title="$t('otp.otp_title_phone', { phone: '0505734123123' })"
            :model-value="otpTextInput"
            name="test"
            :maxlength="6"
            @on-input="(data) => console.log(data)"
            @on-send-code="() => console.log('send code')"
          />
        </div>
      </div>
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: start;
          flex-direction: column;
          padding-top: 50px;
        "
      >
        <p-otp-counter :ttl="3" />
        <p-otp-input
          v-model="otpValue"
          :length="6"
          @on-code-again="() => console.log('code again')"
          @on-expire="() => console.log('expire')"
        />
      </div>
      <div class="place-items-center my-12">
        <div style="width: 60%">
          <div style="display: flex; gap: 10px">
            <div style="width: 48px">
              <p-button
                :status="status"
                :button-type="buttonType"
                :disabled="disabled"
                :loading="loading"
                rounded
                @click="console.log(store.getUserPnl)"
              >
                <p-icon icon="chevron-right" size="xmd" color="fix-white" />
              </p-button>
            </div>
            <div style="width: 48px">
              <p-button
                :status="status"
                :button-type="buttonType"
                :disabled="disabled"
                :loading="loading"
                size="lg"
                @click="console.log('test')"
              >
                <p-icon icon="chevron-right" size="xmd" color="fix-white" />
              </p-button>
            </div>

            <p-button
              :status="status"
              :button-type="buttonType"
              :disabled="disabled"
              :loading="loading"
              @click="console.log('clicked')"
            >
              <p-icon icon="chevron-right" size="xmd" color="fix-white" />
            </p-button>
          </div>
          <div class="place-items-center gap-8 pt-8">
            <p-button @click="loading = !loading"> change loading {{ loading }} </p-button>
            <p-button @click="disabled = !disabled"> change disabled {{ disabled }} </p-button>
            <p-button
              @click="buttonType === 'filled' ? (buttonType = 'ghost') : (buttonType = 'filled')"
            >
              change buttonType {{ buttonType }}
            </p-button>
            <p-button @click="status = status === 'success' ? 'danger' : 'success'">
              change status {{ status }}
            </p-button>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-xs-12 col-md-6 offset-md-3">
            <h1 v-text="$t('title')" />
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-xs-12 col-md-6">Column 1</div>

          <div class="col-xs-12 col-md-6">Column 2</div>
        </div>
        <div class="row">
          <div class="col-xs-6 col-md-3">Column 3</div>

          <div class="col-xs-6 col-md-3">Column 4</div>

          <div class="col-xs-6 col-md-3">Column 5</div>

          <div class="col-xs-6 col-md-3">Column 6</div>
        </div>
      </div>

      <div class="container-fluid">
        <div class="row">
          <div>Full width</div>
        </div>
      </div>

      <div @click="$colorMode.preference = 'light'">Light</div>

      <div @click="$colorMode.preference = 'dark'">Dark</div>

      <div class="container" style="background-color: white; padding: 10px">
        <div class="row">
          <div class="col-4">
            <p-dropdown
              v-model="dropdownValue"
              :options="[
                {
                  value: 'limit',
                  icon: 'chevron-down',
                  title: 'Limit',
                  desc: 'Belirleyeceğiniz birim fiyatından alış emri oluşturun.',
                },
                {
                  value: 'piyasa',
                  icon: 'loading',
                  title: 'Piyasa',
                  desc: 'Marketteki en uygun birim fiyatından alış emri oluşturun.',
                },
              ]"
              size="large"
              label="Label"
            />
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-xs-12 col-md-6 offset-md-3">
            <p-link size="md" label="Label" append-icon="chevron-right" to="/account" />
            <p-loading />
          </div>
        </div>
        <div style="width: 400px; margin-top: 20px; margin-left: auto; margin-right: auto">
          <p-slider v-model="sliderValue" />
          {{ sliderValue }}
          <example-form />
        </div>
      </div>

      <div class="container">
        <div class="row">
          <div class="col-6">
            <p-tag id="test" label="Label" type="box" size="lg" bg-type="strong" />
          </div>
        </div>
        <div>
          <p-chip v-model="chipValue" :selectable="true" image-url="/icon/order-book.svg" />
          <p-chip
            v-model="imageChipValue"
            label="Label"
            :selectable="true"
            prepend-icon="chevron-right"
            append-icon="chevron-right"
          />
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-xs-12 col-md-3 offset-md-4">
            <p-search-input
              v-model="search"
              :show-close="searchFocused || !!search"
              name="search-input"
              :placeholder="$t('title')"
              @on-focus="searchFocused = true"
              @on-close="searchFocused = false"
            />
            <br />

            <div style="margin: 50px 0">
              <p-tab size="lg">
                <p-tab-panel id="umut" title="Umut">
                  <div>BEŞİKTAŞ</div>
                </p-tab-panel>
                <p-tab-panel id="umut2" title="KaraKartal"> Karakartal </p-tab-panel>
                <p-tab-panel id="umut3" icon="loading"> Oldu be icon </p-tab-panel>
                <p-tab-panel id="keyhan" title="Keyhaannn"> keyhan adamdır </p-tab-panel>
              </p-tab>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 col-md-4 offset-md-4">
            <register-form />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const chipValue = ref(false)
const imageChipValue = ref(false)
const sliderValue = ref(0)
const dropdownValue = ref({ value: '', icon: '' })
const loading = ref(false)
const disabled = ref(false)
const buttonType = ref('filled')
const status = ref('success')
const otpValue = ref('')
const otpTextInput = ref('')

const searchFocused = ref(false)
const search = ref('')
useSeoMeta({
  title: $t('title'),
})

const psegmentControl = [
  {
    key: 'Features',
    display: 'Feature',
  },
  {
    key: 'Segments',
    display: 'Segments',
  },
]

const psegmentControlRouter = [
  {
    key: 'Home',
    display: 'home',
    url: '/',
  },
  {
    key: 'account',
    display: 'Account',
    url: '/account',
  },
  {
    key: 'markets',
    display: 'Market List',
    url: '/markets/eth_tl',
  },
  {
    key: 'markets',
    display: 'Market List',
    url: '/markets/ada_tl',
  },
]

const modelValue = ref(0)
</script>
