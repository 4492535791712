<template>
  <div>
    <!-- Form -->
    <h1 style="margin: 20px">Example Form</h1>
    <form class="form">
      <PFormGroup :errors="errors" :errors-name-list="['name', 'surname']">
        <p-text-input v-model="author.name" name="name" type="text" label="Name" />
        <p-text-input
          v-model="author.surname"
          v-mask="'##/##/####'"
          name="surname"
          type="text"
          label="Surname"
        >
          <template #append> TL </template>
        </p-text-input>
      </PFormGroup>
      <p-text-input
        v-model="author.email"
        name="email"
        type="text"
        label="Email"
        @on-input="(va) => console.log(va)"
      >
        <template #append> TL </template>
      </p-text-input>
      <p-text-input
        v-model="author.prepend"
        name="prepend"
        type="text"
        label="Prepend"
        @on-input="(va) => console.log(va)"
      >
        <template #prepend> TL </template>
      </p-text-input>

      <p-text-input
        v-model="author.append"
        name="append"
        type="text"
        label="Append"
        @on-input="(va) => console.log(va)"
      >
        <template #append> TL </template>
      </p-text-input>
      appendAnimation --- p-tooltip
      <p-text-input
        v-model="author.appendAnimation"
        name="appendAnimation"
        type="text"
        label="AppendAnimation"
        @on-input="(va) => console.log(va)"
      >
        <template #append> TL </template>
        <template #appendAnimation>
          <p-tooltip direction="top">
            @
            <template #content> content </template>
          </p-tooltip>
        </template>
      </p-text-input>
      disabled
      <p-text-input
        v-model="author.disabled"
        name="disabled"
        type="text"
        disabled
        label="Disabled"
        @on-input="(va) => console.log(va)"
      >
        <template #prepend> TL </template>
      </p-text-input>
      disabled initialValue
      <p-text-input
        v-model="author.disabledInitialValue"
        name="disabledInıtıalValue"
        type="text"
        label="Label"
        disabled
        @on-input="(va) => console.log(va)"
      >
        <template #append> TL </template>
      </p-text-input>
      readonly
      <p-text-input
        v-model="author.readonlyInitialValue"
        name="readonlyInıtıalValue"
        type="text"
        label="Label"
        readonly
      >
        <template #append> TL </template>
      </p-text-input>
      <p-check-box v-model="author.checkBox" name="checkBox">
        <template #append> checkBox </template>
      </p-check-box>
      <p-check-box v-model="author.checkBox" name="checkBox" disabled>
        <template #append> checkBoxDisabled </template>
      </p-check-box>
      <p-check-box v-model="author.multiCheckBox" :multi-select="true" name="multiCheckBox">
        <template #append> multiCheckBox </template>
      </p-check-box>
      <p-check-box
        v-model="author.multiCheckBox"
        :multi-select="true"
        name="multiCheckBox"
        disabled
      >
        <template #append> multiCheckBoxDisabled </template>
      </p-check-box>
      <p-switch v-model="author.switch" name="switch" />
      <p-switch v-model="author.switch" name="switch" disabled />
      <p-switch v-model="author.switch" name="switch" :loading="true" />
      <p-switch v-model="author.switch" name="switch" :loading="true" size="sm" />
      <p-switch v-model="author.switch" name="switch" disabled size="sm" />
      <div class="flex items-center justify-end space-x-3 mt-7">
        <button type="submit" class="btn submit" :disabled="!meta.valid">Kaydet</button>
      </div>
      {{ author }}
    </form>
  </div>
</template>

<script setup>
import { useForm } from 'vee-validate'
const author = reactive({
  name: 'initial Value',
  surname: '',
  email: '',
  prepend: '',
  append: '',
  appendAnimation: '',
  disabled: '',
  disabledInitialValue: 'disabledInitialValue',
  readonly: '',
  readonlyInitialValue: 'readonlyInitialValue',
  checkBox: true,
  multiCheckBox: true,
  switch: true,
})

const schema = {
  name: {
    requiredGroup: {
      label: $t('formElementName.name'),
    },
    min: 5,
  },
  surname: {
    requiredGroup: {
      label: $t('formElementName.surname'),
    },
    min: 5,
  },
  email: 'required|email',
  prepend: {
    required: true,
    maxLengthWithLabel: {
      length: 2,
      label: 'prepend',
    },
  },
  append: '',
  appendAnimation: '',
  disabled: '',
  disabledInitialValue: '',
  readonlyInitialValue: '',
  checkBox: 'required',
  multiCheckBox: 'required',
}

const { meta, errors } = useForm({
  validationSchema: schema,
})
</script>

<style lang="scss">
.form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
</style>
