<template>
  <div :id="id" :class="[chipAttributes, selectedAttributes]" @click="toggleHandler">
    <template v-if="imageUrl">
      <nuxt-img :src="imageUrl" width="16" height="16" />
    </template>
    <template v-else>
      <p-icon v-if="prependIcon" :icon="prependIcon" size="xs" color="" />
      <p v-if="props.label">
        {{ label }}
      </p>
      <p-icon v-if="appendIcon" :icon="appendIcon" size="xs" color="" />
    </template>
  </div>
</template>

<script setup lang="ts">
const emit = defineEmits(['on-change', 'update:modelValue'])

const props = defineProps({
  id: {
    type: String,
    default: 'Provide an ID',
  },
  selected: {
    type: Boolean,
    default: false,
    validator: (val: boolean) => [true, false].includes(val),
  },
  label: {
    type: String,
    default: '',
  },
  modelValue: {
    type: Boolean,
    default: false,
  },
  selectable: {
    type: Boolean,
    default: false,
  },
  prependIcon: {
    type: String,
    default: '',
  },
  appendIcon: {
    type: String,
    default: '',
  },
  imageUrl: {
    type: String,
    default: '',
  },
})

const value = computed({
  get: () => props.modelValue,
  set: (val) => emit('update:modelValue', val),
})

const chipAttributes = computed(() => {
  return props.imageUrl ? 'p-chip f-body-12 p-chip__image' : 'p-chip f-body-12 p-chip__regular'
})

const selectedAttributes = computed(() => {
  return value.value && props.selectable ? 'p-chip__selected' : 'p-chip__deselected'
})

const toggleHandler = () => {
  value.value = !value.value
}
</script>
