<template>
  <div v-bind="tagAttributes">
    <p v-bind="labelAttributes" v-text="label" />
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  id: {
    type: String,
    default: 'Provide an ID',
  },
  label: {
    type: String,
    default: 'Label',
  },
  size: {
    type: String,
    default: 'sm',
    validator: (val: string) => ['sm', 'md', 'lg'].includes(val),
  },
  bgType: {
    type: String,
    default: 'strong',
    validator: (val: string) => ['light', 'strong'].includes(val),
  },
  type: {
    type: String,
    default: 'success',
    validator: (val: string) => ['success', 'system', 'danger', 'warning', 'box'].includes(val),
  },
})

const tagAttributes = computed(() => {
  return {
    class: {
      'p-tag': true,
      [`p-tag__${props.size}`]: props.size,
      [`p-tag__${props.type}--${props.bgType}`]: props.type && props.bgType,
    },
  }
})

const labelAttributes = computed(() => {
  return {
    class: {
      'f-utility-14-medium': props.size === 'lg',
      'f-utility-12-medium': props.size === 'md',
      'f-utility-10-medium': props.size === 'sm',
    },
  }
})
</script>
